import React from "react"
import styled from '@emotion/styled';

import translate from "../utils/translation"

const QuestionContainer = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
`;

const Question = styled.h2`
  width: 96%;
  display: inline-block;
  padding-right: 50px;
  font-size: 24px;
  margin: 0;
  padding: 25px 0;
`;

const Answer = styled.p`
  line-height: 1.88;
  font-size: 16px;
  margin: 0;
  padding: 0 0 25px;
  display: ${props => props.isCollapsed && "none"}
`;

const QuestionButton = styled.div`
  width: 3.2px;
  height: 16px;
  position: relative;
  display: inline-block;
  
  &:hover {
    cursor: pointer;
  }
  
  background: ${props => !props.isCollapsed && "#2f2f2f"};
  
  &:after {
    background: #2f2f2f;
    content: "";
    height: 3.2px;
    left: -6.4px;
    position: absolute;
    top: 6.4px;
    width: 16px;
  }
`;

const LineBreak = styled.div`
  height: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1px;
`;


export default class QABlock extends React.Component {
  constructor() {
    super();
    this.handleToggle = this.handleToggle.bind(this);
    this.state = {
      isCollapsed: true,
    };
  }

  handleToggle() {
    this.setState({ isCollapsed: !this.state.isCollapsed });
  }

  render() {
    const {question, answer} = this.props;
    return (
      <div>
        <QuestionContainer>
          <Question>{translate(question)}</Question>
          <QuestionButton onClick={this.handleToggle} isCollapsed={this.state.isCollapsed}/>
        </QuestionContainer>
        <Answer isCollapsed={this.state.isCollapsed}>{translate(answer)}</Answer>
        <LineBreak />
      </div>
    );
  }
}