import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

import translate from '../../utils/translation';
import QABlock from '../../components/qABlock';
import About from '../../components/about';
import Button from '../../components/common/button';
import { MainTitle } from '../../components/common/title';

const Container = styled.div`
  padding: 170px 0;

  @media (max-width: 990px) {
    padding: 0 24px;
  }
`;

const HaveQuestions = styled.h2`
  font-size: 30px;
  letter-spacing: 0.8px;
  font-weight: bold;
  text-align: center;
  margin-top: 200px;
  
  @media (max-width: 760px) {
    font-size: 24px;
  }
`;

const ContactUsButton = styled(Button)`
  display: block;
  margin: auto;
  cursor: pointer;
`;

export default () => <About aboutIndex={2}>
  <Container>
    <MainTitle>{translate("headers.faqs")}</MainTitle>
    {Array.from(Array(4), (_, index) => (
      <QABlock question={`faqs.FAQ${index + 1}`} answer={`faqs.FAQ${index + 1}Answer`} />
    ))}
    <HaveQuestions>
      {translate("haveQuestions")}
    </HaveQuestions>
    <ContactUsButton className="solid" width="120px" height="36px">
      <Link to="/contact/">{translate("contactUs")}</Link>
    </ContactUsButton>
  </Container>
</About>
  ;
